
.update{
    border-radius: .7rem;
    width: 85%;
    background-color: #fff;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    flex-direction: column;
    font-size: 13px;
}

.updat{
    display: flex;
    gap: .5rem;
}
.updat >img{
    width: 3.2rem;
    height: 3.2rem;
}

.noti>div>span:nth-of-type(1){
    font-weight: bold;
}