
.main-dash{
display: flex;
justify-content: space-evenly;
flex-direction: column;
}

@media screen and (max-width:1200px){
    .main-dash{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}
@media screen and (max-width:768px){
    .main-dash{
        align-items: center;
    }
}