*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}




