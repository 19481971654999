.root{
    --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.5%);
    --orange: #fca61f;
    --black: #242d49;
    --gray: #788097;
    --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
    --pink: #ff919d;
    --glass: rgba(255, 255, 255, 0.54);
    --boxShadow: 0 19px 60px rgba( 0, 0, 0 / 8%);
    --smBoxShadow: -71px 51px 60px, rgba( 0, 0, 0, 0.08 );
  
}

.App{
    font-family: 'Inter', sans-serif;
    display: flex;
    min-height: 100vh;
    background:  linear-gradient(106.37deg, #ffe1bc 29.63%,   #ffcfd1 51.55%, #f3c6f1 90.85%);
    justify-content: center;
    align-items: center;
}

.app-glass{
    display: grid;
    grid-template-columns: 11rem auto 20rem;
    min-height: 100%;
    width: 97%;
    border-radius: 2rem;
    background-color: rgba(255, 255, 255, 0.54);
    overflow: hidden;
    gap: 10px;
}
 
@media screen and (max-width:1200px) {
    .app-glass{
        grid-template-columns: 10% 50% auto;
        overflow-y: scroll;
    }
}
    

@media screen and (max-width:768px){
    .app-glass{
        grid-template-columns: 1fr;
    }
    .app-glass::-webkit-progress-bar{
        display: none;
    }
}