
.side-bar{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 3rem;
    transition: all 300ms ease;
}

.logo{
    display: flex;
    font-weight: bold;
    font-size: 22px;
    height: 5rem;
    gap: .8rem;
    align-items: center;
    justify-content: center;
    height: 4%;
    overflow: hidden;
}
.logo >img{
    height: 3rem;
    width: 3rem;
}

.logo >span >span{
    color: #ff919d;
}

.menu{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1.5rem;
}

.menu-item{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;
    height: 2.3rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
    
}

.menu-item:hover{
    cursor: pointer;
}

.active{
    margin-left: 0;
    background:  #f799a354;
    border-radius: 0.7rem;
    padding-left: .5rem;
}
.active::before{
    content: '';
    width: 8px;
    height: 100%;
    background-color: #ff919d;
    
}

.menu .menu-item:last-child{
    margin-top: 2.3rem;
    width: 100%;
}

.bars{
    display: none;
}

@media screen and (max-width:1200px){
    .logo{
        display: none;
    }
    .menu-item>span{
        display: none;
    }
}


@media screen and (max-width:768px){
    .side-bar{
        
        position: fixed;
        padding-right: 1rem;
        z-index: 9;
        background-color: #ffe0e0;
        width: 55%;
        height: 100%;
    }
    .menu-item>span{
        display: block;
    }
    .logo{
        display:flex ;
    }
    .menu .menu-item:last-child{
        position: relative;
        margin-top: 6rem;
    }
    .bars{
        display: flex;
        top: 1rem;
        position: fixed;
        z-index: 9;
        background: #ffe0e0;
        padding: 10px;
        border-radius: 10px;
    }
}