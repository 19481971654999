.MuiTableRow-root>*{
padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head>*{
    font-weight: bold!important;
}
.table td, th{
    border: none!important;
}

.status{
    padding: 8px;
    border-radius: 9px;
}

@media screen and (max-width:1200px){
    .table{
        width:170%;
        margin-top: 4rem;
    }
}

@media screen and (max-width:768px){
    .table{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 18rem;
        align-items: center;
    }
}